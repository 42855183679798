import React, { useEffect, useState } from 'react';
import Slider, { Slide } from '../../_common/Slider';

import ProductImage from '../ProductImage';
import Tag from '../../_common/Tag';
import { getPimValue } from '../../../utils/utils';

export const ProductImages = ({
  mainSizes,
  previewParams,
  previewSizes,
  product,
  selectedVariantSku,
  site,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [mainSliderRef, setMainSliderRef] = useState(null);
  const [previewSliderRef, setPreviewSliderRef] = useState(null);

  const images = product.images;

  const mapSkuToIndex = (images) => {
    const skuImageUrlMap = images.reduce((acc, image) => {
      product.variants.forEach((variant) => {
        variant.images.forEach((variantImage) => {
          if (
            variantImage.url === image.url &&
            !acc[getPimValue(variant.sku, site)]
          ) {
            acc[getPimValue(variant.sku, site)] = image.url;
          }
        });
      });

      return acc;
    }, {});

    const skuIndexMap = Object.entries(skuImageUrlMap).reduce(
      (acc, [sku, imageUrl]) => {
        acc[sku] = images.findIndex((image) => image.url === imageUrl);
        return acc;
      },
      {}
    );

    return skuIndexMap;
  };

  useEffect(() => {
    const skuIndexMap = mapSkuToIndex(images);
    if (!skuIndexMap || typeof skuIndexMap[selectedVariantSku] === undefined) {
      return;
    }

    const index = skuIndexMap[selectedVariantSku];
    setActiveIndex(index);
  }, [selectedVariantSku]);

  useEffect(() => {
    if (!mainSliderRef?.current || !previewSliderRef.current) return;
    mainSliderRef.current.swiper.slideTo(activeIndex);
    previewSliderRef.current.swiper.slideTo(activeIndex);
  }, [activeIndex]);

  const params = {
    centeredSlides: true,
    centerInsufficientSlides: true,
    navigation: 'inline',
    slidesPerView: 8,
    injectStyles: [
      ':host .swiper-vertical > .swiper-wrapper { position: absolute !important; }',
    ],
    breakpoints: {
      768: {
        direction: 'vertical',
        parentHeightSelector: '.product-images',
        slidesPerView: 8,
      },
    },
    ...previewParams,
  };

  return (
    <div className="product-images__wrapper">
      <Slider
        activeIndex={activeIndex}
        className="product-images"
        onChange={setActiveIndex}
        onClick={setActiveIndex}
        onLoad={setMainSliderRef}
      >
        {images.map((image) => (
          <Slide key={image.url}>
            <ProductImage
              image={image}
              sizes={
                mainSizes || [
                  { query: '(max-width: 479px)', containerSize: '424px' },
                  { query: '(max-width: 767px)', containerSize: '712px' },
                  { query: '', containerSize: '1040px' },
                ]
              }
            >
              {product.badge?.value?.en !== 'None' && (
                <Tag title={product.badge?.value?.en} />
              )}
            </ProductImage>
          </Slide>
        ))}
      </Slider>

      <Slider
        activeIndex={activeIndex}
        className="product-images__preview"
        onChange={setActiveIndex}
        onClick={setActiveIndex}
        onLoad={setPreviewSliderRef}
        params={params}
      >
        {images.map((image) => (
          <Slide key={`${image.url}-preview`}>
            <ProductImage
              image={image}
              sizes={previewSizes || [{ query: '', containerSize: '100px' }]}
            />
          </Slide>
        ))}
      </Slider>
    </div>
  );
};

export default ProductImages;
